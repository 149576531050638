<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn " >
      <b-row class = "row justify-content-center">
        <b-col cols="8" >
          
            <div slot="header">
              <i class="fa fa-leaf"></i><strong>  Crééz une nouvelle culture{{activatedCulture}} </strong> 
              <b-form>
                    <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                    
                    <c-input type="image" v-model="picture" default="DEFAULT_CULTURE_PICTURE"></c-input>
                    
                    <c-input container-class="mb-3 mt-3" label="Nom de la culture" placeholder="Ex: Cacao"
                              v-model="name" :state="nameState">
                      Veuillez saisir un nom
                    </c-input>
                    <!-- <b-input-group v-if="stepCulturesList.length">
                      <b-badge variant="secondary" pill class="m-1 py-1" v-for="step_culture in stepCulturesList">
                        {{step_culture.name}}
                        <b-link @click.prevent="removeStepCulture(step_culture)">
                          <i class="fa fa-remove text-danger"></i>
                        </b-link>
                      </b-badge>
                    </b-input-group>
                    <p class="text-center" v-else>Aucune étape de culture.</p> -->
                    
                    <b-input-group class="mb-3 mt-3">
                      <b-autocomplete-input placeholder="Ajouter une étape de culture" v-model="step_culture"
                                            :data="stepCultureAutocompleteData" class="autocomplete"
                                            @hit="addStepCulture($event)" ref="stepCultureAutocompleteInput"
                                            :serializer="item => item.name">
                        <template slot="append">
                          <b-button variant="primary" @click.prevent="addStepCulture()">
                            Ajouter
                          </b-button>
                        </template>
                      </b-autocomplete-input>
                    </b-input-group>

                    <div class="text-center" v-if="fetchingStepList">
                      <b-spinner></b-spinner>
                      <br>
                      Chargement...
                    </div>

                    <div>
                      <b-row class = "row justify-content-left">
                        <b-col cols="3" >
                        
                          <ul class="list-group">
                            <li
                              v-for="item in stepCultureAutocompleteData"
                              :key="item.id"
                              
                              class="list-group-item list-group-item-action hover-highlight"
                              @click="selectCulture(item)"
                            >
                              
                            <i class="fa fa-plus mr-2"></i>
                              
                            {{ item.name }}
                              
                            </li>
                          </ul>
                          
                          <li class="list-group-item list-group-item-success mb-3 hover-highlight"
                          @click="newStepModal = true">
                          <i class="fa fa-plus-circle mr-2"></i>Creer une nouvelle etape</li>
                        
                        </b-col>
                        
                        <b-col cols="3" >
                            <p class="text-center" v-if="!stepCulturesList.length">Aucune étape de culture selectionnée.</p>
                          
                            <ul class="list-group">
                              <li
                                v-for="item in stepCulturesList"
                                :key="item.id"
                                
                                class="list-group-item list-group-item-action hover-highlight"
                                
                              >
                              
                              {{ item.name }}
                              <b-link @click.prevent="removeStepCulture(item)">
                                <i class="fa fa-remove text-danger"></i>
                              </b-link>
                                
                              </li>
                            </ul>
                                                      
                        </b-col>
                      </b-row>

                    </div>


                    <c-input container-class="mb-4" type="textarea" label="Commentaire"
                              placeholder="Entrez un commentaire..." v-model="comment">
                    </c-input>
                  </b-form>
                  <div class="w-100 text-left">
                    <button-spinner variant="success" type="submit" class="px-4 mb-5" @click="onSubmit"
                                    :fetching="fetchingNewCulture">
                      {{submitBtnText}}
                    </button-spinner>
                    
                  </div>

                <b-modal :title="stepModalTitle" class="modal-primary" v-model="newStepModal"
                        @hide="onStepModalClose">
                    <b-form>
                      <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                      
                      <c-input container-class="mb-3 mt-3" label="Nom de l'étape" placeholder="Ex: Préparation du sol"
                              v-model="stepName" :state="stepNameState">
                        Veuillez saisir un nom
                      </c-input>
                      <!-- <b-input-group v-if="activitiesList.length">
                        <b-badge variant="secondary" pill class="m-1 py-1" v-for="activity in activitiesList">
                          {{activity.name}}
                          <b-link @click.prevent="removeActivity(activity)">
                            <i class="fa fa-remove text-danger"></i>
                          </b-link>
                        </b-badge>
                      </b-input-group>
                      <p class="text-center" v-else>Aucune activité.</p> -->
                      
                      <b-input-group class="mb-3 mt-3">
                        <b-autocomplete-input placeholder="Ajouter une activité" v-model="activity"
                                              :data="activityAutocompleteData" class="autocomplete"
                                              @hit="addActivity($event)" ref="activityAutocompleteInput"
                                              :serializer="item => item.name">
                          <template slot="append">
                            <b-button variant="primary" @click.prevent="addActivity()">
                              Ajouter
                            </b-button>
                          </template>
                        </b-autocomplete-input>
                      </b-input-group>

                      <div class="text-center" v-if="fetchingActivityList">
                        <b-spinner></b-spinner>
                        <br>
                        Chargement...
                      </div>

                      <b-row class = "row justify-content-left">
                        <b-col cols="6" >
                        
                          <ul class="list-group">
                            <li
                              v-for="item in activityAutocompleteData"
                              :key="item.id"
                              
                              class="list-group-item list-group-item-action hover-highlight"
                              @click="selectActivity(item)"
                            >
                              
                            <i class="fa fa-plus mr-2"></i>
                              
                            {{ item.name }}
                              
                            </li>
                          </ul>
                          
                          <li class="list-group-item list-group-item-success mb-3 hover-highlight"
                          @click="newActivityModal = true">
                          <i class="fa fa-plus-circle mr-2"></i>Creer une nouvelle Activité</li>
                        
                        </b-col>
                        
                        <b-col cols="6" >
                            <p class="text-center" v-if="!activitiesList.length">Aucune activité selectionnée.</p>
                          
                            <ul class="list-group">
                              <li
                                v-for="item in activitiesList"
                                :key="item.id"
                                
                                class="list-group-item list-group-item-action hover-highlight"
                                
                              >
                              
                              {{ item.name }}
                              <b-link @click.prevent="removeActivity(item)">
                                <i class="fa fa-remove text-danger"></i>
                              </b-link>
                                
                              </li>
                            </ul>
                                                      
                        </b-col>
                      </b-row>
                      
                      <c-input container-class="mb-3" type="textarea" label="Commentaire"
                              placeholder="Entrez un commentaire..." v-model="stepComment">
                      </c-input>
                      

                      <b-modal :title="activityModalTitle" class="modal-primary" v-model="newActivityModal"
                        @hide="onActivityModalClose">
                <b-form>
                  <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                  
                  <c-input container-class="mb-3 mt-3" label="Nom de l'activité" placeholder="Ex: Abattage"
                            v-model="activityName" :state="activityNameState">
                    Veuillez saisir un nom
                  </c-input>
                  <p>
                    Surface considérée :
                    <default-unit>1 ha</default-unit>
                  </p>
                  <c-input container-class="mb-2" type="quantity" label="Durée de l'activité"
                            placeholder="Ex: 30"
                            v-model="activityDuration" unit="Jour(s)">
                  </c-input>
                  <c-input container-class="mb-2" type="quantity" label="Coût total de l'activité"
                            placeholder="Ex: 50000"
                            v-model="activityCost" :unit="exploitationCurrency+' / pers. / jour'">
                  </c-input>
                  <c-input container-class="mb-3 mt-3" label="Nombre personnes de l'activité" placeholder="Ex: 50"
                            v-model="activityPersonNumber">
                  </c-input>
                  
                  <b-input-group v-if="equipments.length">
                    <b-badge variant="secondary" pill class="m-1 py-1" v-for="equipment in equipments">
                      {{equipment.name}} - {{equipment.quantity}}
                      <b-link @click.prevent="removeEquipment(equipment)">
                        <i class="fa fa-remove text-danger"></i>
                      </b-link>
                    </b-badge>
                  </b-input-group>
                  <p class="text-center" v-else>Aucun matériel.</p>
                  
                  <b-input-group class="mb-3 mt-3">
                    <b-autocomplete-input placeholder="Ajouter un matériel" v-model="equipment"
                                          :data="equipmentAutocompleteData" class="autocomplete"
                                          @hit="addEquipment($event)" ref="equipmentAutocompleteInput"
                                          :serializer="item => item.name">
                      <template slot="append">
                        <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                                      v-model="quantityEquipment" min="0" step="1" :state="quantityStateAdd"
                        />
                        <b-button variant="primary" @click.prevent="addEquipment()">
                          Ajouter
                        </b-button>
                      </template>
                    </b-autocomplete-input>
                  </b-input-group>
                  
                  <b-input-group v-if="inputs.length">
                    <b-badge variant="secondary" pill class="m-1 py-1" v-for="input in inputs">
                      {{input.name}} - {{input.quantity}}
                      <b-link @click.prevent="removeInput(input)">
                        <i class="fa fa-remove text-danger"></i>
                      </b-link>
                    </b-badge>
                  </b-input-group>
                  <p class="text-center" v-else>Aucun intrant.</p>
                  
                  <b-input-group class="mb-3 mt-3">
                    <b-autocomplete-input placeholder="Ajouter un intrant" v-model="input"
                                          :data="inputAutocompleteData" class="autocomplete"
                                          @hit="addInput($event)" ref="inputAutocompleteInput"
                                          :serializer="item => item.name">
                      <template slot="append">
                        <b-form-input type="number" class="form-control input-number-add" placeholder="Qté"
                                      v-model="quantityInput" min="0" step="1" :state="quantityStateAdd"
                        />
                        <b-button variant="primary" @click.prevent="addInput()">
                          Ajouter
                        </b-button>
                      </template>
                    </b-autocomplete-input>
                  </b-input-group>
                  
                  <c-input container-class="mb-3" type="textarea" label="Commentaire"
                            placeholder="Entrez un commentaire..." v-model="activityComment">
                  </c-input>
                </b-form>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitActivity"
                                  :fetching="fetchingNewActivity">
                    {{submitActivityBtnText}}
                  </button-spinner>
                  <b-button variant="secondary" @click="newActivityModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
                    </b-form>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitStep"
                                  :fetching="fetchingNewStep">
                    {{submitStepBtnText}}
                  </button-spinner>
                  <b-button variant="secondary" @click="newStepModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
            </div>
            
          
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  
<script>
  import {Api, Regex, Toast, asset, env, IO, String, price} from "../../../helpers"
  
  export default {
    name: "ExploitationCreateCulture",
    title: "PIA - Cultures",
    data() {
      return {
        cultureData: {
          "name": "Cacao",
          "comment": "Culture du cacao sur 1 hectare",
          "cultureSteps": [
            {
              "name": "Préparation du terrain",
              "comment": "Préparation du sol pour la plantation du cacao",
              "activities": [
                {
                  "name": "Défrichement",
                  "comment": "Eliminer la végétation existante",
                  "duration": "1 mois",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Hache",
                      "quantity": "2"
                    },
                    {
                      "name": "Faux",
                      "quantity": "4"
                    }
                  ],
                  "inputs": []
                },
                {
                  "name": "Labourage",
                  "comment": "Travail du sol pour aérer et ameublir le sol",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Tracteur",
                      "quantity": "1"
                    },
                    {
                      "name": "Charrue",
                      "quantity": "1"
                    }
                  ],
                  "inputs": []
                },
                {
                  "name": "Fumure",
                  "comment": "Apport de matière organique au sol",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Epandeuse",
                      "quantity": "1"
                    }
                  ],
                  "inputs": [
                    {
                      "name": "Engrais organique",
                      "quantity": "5 tonnes"
                    }
                  ]
                },
                {
                  "name": "Préparation des plants",
                  "comment": "Choix et préparation des plants de cacao",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [],
                  "inputs": [
                    {
                      "name": "Plants de cacao",
                      "quantity": "1000"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Plantation",
              "comment": "Mise en place des plants de cacao",
              "activities": [
                {
                  "name": "Plantation",
                  "comment": "Planter les plants de cacao dans le sol",
                  "duration": "1 mois",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [],
                  "inputs": []
                }
              ]
            },
            {
              "name": "Entretien",
              "comment": "Entretien régulier de la plantation de cacao",
              "activities": [
                {
                  "name": "Désherbage",
                  "comment": "Eliminer les mauvaises herbes autour des plants de cacao",
                  "duration": "1 mois",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Bêche",
                      "quantity": "2"
                    },
                    {
                      "name": "Herbicide",
                      "quantity": "10 litres"
                    }
                  ],
                  "inputs": []
                },
                {
                  "name": "Taille",
                  "comment": "Couper les branches et les feuilles superflues pour favoriser la croissance du cacao",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Sécateur",
                      "quantity": "2"
                    },
                    {
                      "name": "Scie",
                      "quantity": "1"
                    }
                  ],
                  "inputs": []
                },
                {
                  "name": "Fertilisation",
                  "comment": "Apport d'éléments nutritifs au sol",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Epandeuse",
                      "quantity": "1"
                    }
                  ],
                  "inputs": [
                    {
                      "name": "Engrais chimique",
                      "quantity": "2 tonnes"
                    }
                  ]
                },
                {
                  "name": "Protection",
                  "comment": "Protection des plants de cacao contre les maladies et les parasites",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Pulvérisateur",
                      "quantity": "1"
                    }
                  ],
                  "inputs": [
                    {
                      "name": "Fongicide",
                      "quantity": "10 litres"
                    },
                    {
                      "name": "Insecticide",
                      "quantity": "10 litres"
                    }
                  ]
                }
              ]
            },
            {
              "name": "Récolte",
              "comment": "Récolte des cabosses de cacao",
              "activities": [
                {
                  "name": "Récolte",
                  "comment": "Ramassage des cabosses mûres",
                  "duration": "1 mois",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Panier",
                      "quantity": "10"
                    }
                  ],
                  "inputs": []
                }
              ]
            },
            {
              "name": "Post-Récolte",
              "comment": "Traitement des cabosses de cacao après la récolte",
              "activities": [
                {
                  "name": "Séchage",
                  "comment": "Sécher les cabosses de cacao pour réduire l'humidité",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Séchoir",
                      "quantity": "1"
                    }
                  ],
                  "inputs": []
                },
                {
                  "name": "Fermentation",
                  "comment": "Faire fermenter les cabosses de cacao pour développer le goût et l'arôme",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [],
                  "inputs": []
                },
                {
                  "name": "Nettoyage",
                  "comment": "Nettoyer les fèves de cacao après la fermentation",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Crible",
                      "quantity": "1"
                    }
                  ],
                  "inputs": []
                },
                {
                  "name": "Emballage",
                  "comment": "Emballer les fèves de cacao pour la vente",
                  "duration": "1 semaine",
                  "cost": "",
                  "personNumber": "",
                  "equipments": [
                    {
                      "name": "Sacs",
                      "quantity": "100"
                    }
                  ],
                  "inputs": []
                }
              ]
            }
          ]
        },
        stepscult:[] ,
        activatedCulture: '',
        cultures: [],
        fields: [
          {key: 'picture', label: 'Aperçu', asset: this.asset},
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'comment', label: 'Commentaire'},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier', handler: this.editCulture},
          {type: 'danger', icon: 'icon-trash', title: 'Supprimer', handler: this.deleteCulture},
        ],
        picture: null,
        name: '',
        comment: '',
        newCultureModal: false,
        fetchingNewCulture: false,
        fetchingCultureList: false,
        selectedCulture: null,
        
        submitted: false,
        error: null,
        
        steps: [],
        stepsFields: [
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'comment', label: 'Commentaire'},
          {key: 'actions', label: 'Actions'},
        ],
        stepsActions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier', handler: this.editStep},
          {type: 'danger', icon: 'icon-trash', title: 'Supprimer', handler: this.deleteStep},
        ],
        stepName: '',
        stepComment: '',
        newStepModal: false,
        fetchingNewStep: false,
        fetchingStepList: false,
        selectedStep: null,
        
        activities: [],
        activitiesFields: [
          {key: 'name', label: 'Nom', sortable: true},
          {key: '_equipments', label: 'Matériel'},
          {key: '_inputs', label: 'Intrants'},
          // {key: 'comment', label: 'Commentaire'},
          {key: 'actions', label: 'Actions'},
        ],
        activitiesActions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier', handler: this.editActivity},
          {type: 'danger', icon: 'icon-trash', title: 'Supprimer', handler: this.deleteActivity},
        ],
        activityName: '',
        activityDuration: '',
        activityCost: '',
        activityPersonNumber: '',
        activityComment: '',
        newActivityModal: false,
        fetchingNewActivity: false,
        fetchingActivityList: false,
        selectedNodeData: null,
        equipments: [],
        equipment: '',
        quantityEquipment: '',
        quantityInput: '',
        equipmentAutocompleteData: [],
        fetchingEquipmentList: false,
        inputs: [],
        input: '',
        inputAutocompleteData: [],
        fetchingInputList: false,
        
        cultureIndex: 0,
        currentCulture: null,
        currentStep: '',
        currentActivity: null,
        fetchingAllCultures: false,
        fetchingActivityInformations: false,
        cultureFilter: '',
        treeOptions: {
          filter: {
            emptyText: "Aucune culture ne correspond à votre recherche",
            showChildren: true
          }
        },
        allData: [],
        treeData: [
          {
            text: 'Bonjour',
            data: {
              created: true
            },
            children: [{
              text: 'hello',
            }]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          },
          {
            text: 'Salut',
            children: [
              {
                text: 'bouage'
              }
            ]
          }
        ],
        treeDataActivities: [],
        treeDataStepsCultures: [],
        activityDataInputs: [
          {
            id: 0,
            name: 'engrais',
            quantity: 12,
            unit: 'KCl'
          },
          {
            id: 1,
            name: 'engrais',
            quantity: 12,
            unit: 'KCl'
          },
        ],
        activityDataEquipments: [
          {
            id: 0,
            name: 'Machette',
            quantity: 12,
          },
          {
            id: 1,
            name: 'Houe',
            quantity: 23,
          },
        ],
        activityDataDuration: 15,
        activityDataCost: 15,
        activityDataPersonNumber: 15,
        columnActiviyModeDisabled: true,
        columnActiviyMessage: 'Selectionner une etape de culture pour pouvoir visualiser le message',
        
        stepCulturesList: [],
        stepCultureAutocompleteData: [],
        step_culture: '',
        activity: '',
        activityAutocompleteData: [],
        activitiesList: [],
        submitAddEquipement: false,
  
        myOptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Quittez',
            buttonPrevious: 'Précédent',
            buttonNext: 'Suivant',
            buttonStop: 'Terminé'
          }
        },
      }
    },
    created() {
      this.fetchingCultureList = true
      Api.get('/exploitation/culture/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.cultures = res.data.data.filter(item => item.isOwn)
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCultureList = false
        })
      
      this.fetchingStepList = true
      Api.get('/exploitation/culture/step/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.steps = res.data.data.filter(item => item.isOwn)
            this.stepCultureAutocompleteData = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStepList = false
        })
      
      this.fetchingActivityList = true
      Api.get('/exploitation/activity/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.activities = res.data.data.filter(item => item.isOwn)
            this.activityAutocompleteData = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingActivityList = false
          
        })
      
      this.fetchingEquipmentList = true
      Api.get('/exploitation/equipment/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.equipmentAutocompleteData = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEquipmentList = false
        })
      
      this.fetchingInputList = true
      Api.get('/exploitation/input/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.inputAutocompleteData = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingInputList = false
        })
      
      
      this.fetchingAllCultures = true
      Api.get('/exploitation/culture/resume', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.allData = res.data.data.sort((a,b) => {
              if(a.isOWn) return -1
              else if (b.isOwn) return 1
              else return 0
            })
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingAllCultures = false
        })
      //
      // this.$nextTick(()=>{
      //   this.$tours['myTour'].start()
      // })
      //
    },
    mounted(){
    
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
      allData() {
        this.treeData = this.allData.map(item => {
          let result = {
            text: item.name,
            data: {
              isOwn: item.isOwn,
              id: item.id,
              code: 'CULTURE'
            },
            children: item.cultureSteps.map(step => ({
              text: step.name,
              data: {
                isOwn: step.isOwn,
                id: step.id,
                code: 'STEP_CULTURE',
                childrenLength: step.activities.length
              },
            }))
          }
          return result
        })
        this.$refs.cultureTree.setModel(this.treeData)
        this.columnActiviyModeDisabled = true
        this.fetchingActivityInformations = false
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      }
      ,
      rootNodes() {
      // Return the root nodes
      return this.treeData.filter(node => !node.parentId);
      }
      ,
      hasError() {
        return !!this.error
      }
      ,
      errorMessage() {
        return this.error ? this.error.message : null
      }
      ,
      
      _picture() {
        return this.picture ? this.picture : env('DEFAULT_CULTURE_PICTURE')
      }
      ,
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      }
      ,
      submitBtnText() {
        return this.selectedCulture ? 'Enregistrer' : 'Créer'
      }
      ,
      modalTitle() {
        return this.selectedCulture ? 'Modifier la culture' : 'Nouvelle culture'
      }
      ,
      
      stepNameState() {
        return !this.submitted || this.stepName.trim().length >= 3 ? null : false
      }
      ,
      submitStepBtnText() {
        return this.selectedStep ? 'Enregistrer' : 'Créer'
      }
      ,
      stepModalTitle() {
        return this.selectedStep ? "Modifier l'étape" : 'Nouvelle étape de culture'
      }
      ,
      
      activityNameState() {
        return !this.submitted || this.activityName.trim().length >= 3 ? null : false
      }
      ,
      submitActivityBtnText() {
        return this.selectedNodeData ? 'Enregistrer' : 'Créer'
      }
      ,
      activityModalTitle() {
        return this.selectedNodeData ? "Modifier l'activité" : 'Nouvelle activité'
      }
      ,
      _activities() {
        return this.activities.map(a => ({
          ...a,
          _inputs: a.inputs.map(i => i.name).join(', '),
          _equipments: a.equipments.map(i => i.name).join(', '),
        }))
      },
      quantityStateAdd() {
        return this.submitAddEquipement || typeof this.quantityEquipment == 'number'
      },
      exploitationCurrency() {
        return price()
      }
    }
    ,
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_CULTURE_PICTURE'))
      }
      ,

      switchTab(tabIndex) {
      this.activeTab = tabIndex;
      }
      ,
      
      onPictureChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        
        const reader = new FileReader();
        const vm = this;
        
        reader.onload = (e) => {
          vm.picture = e.target.result
        }
        reader.readAsDataURL(files[0]);
      }
      ,
      valid() {
        return this.name.trim().length >= 3
      }
      ,
      onModalClose() {
        this.submitted = false
        this.error = null
        this.fetchingNewCulture = false
        this.picture = null
        this.name = ''
        this.comment = ''
        this.selectedCulture = null
        this.stepCulturesList = []
      }
      ,
      newCulture() {
        this.submitted = true
        if (!this.valid()) return
        
        this.fetchingNewCulture = true
        Api.post('/exploitation/culture/create', {
          exploitationId: this.exploitationId,
          name: this.name,
          comment: this.comment,
          picture: this.picture,
          cultureSteps: this.stepCulturesList.map(item => item.id),
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newCultureModal = false
              this.cultures.push(res.data.data)
              this.allData.push(res.data.data)
              
              //Toast.success('Culture créée avec succès !')
              this.$router.push(`/exploitation/${this.exploitationId}/cultures/ownlist`);
              Toast.success('Culture créée avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewCulture = false
          })
      }
      ,
      updateCulture() {
        this.submitted = true
        if (!this.valid()) return
        
        this.fetchingNewCulture = true
        Api.post('/exploitation/culture/update', {
          exploitationId: this.exploitationId,
          cultureId: this.selectedCulture.id,
          name: this.name,
          comment: this.comment,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
          cultureSteps: this.stepCulturesList.map(item => item.id)
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newCultureModal = false
              this.cultures = this.cultures.filter((item, index, array) => item.id !== this.selectedCulture.id)
              this.cultures.push(res.data.data)
              this.allData = this.allData.map(item => item.id == res.data.data.id ? res.data.data : item)
              
              Toast.success('Culture mise à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewCulture = false
          })
      }
      ,
      onSubmit() {
        this.selectedCulture ? this.updateCulture() : this.newCulture()
      }
      ,
      editCulture(culture) {
        this.selectedCulture = culture
        this.picture = asset(culture.picture)
        this.name = culture.name
        this.comment = culture.comment
        this.newCultureModal = true
        this.stepCulturesList = this.allData.find(item => item.id == culture.id).cultureSteps
      }
      ,
      deleteCulture(culture) {
        const res = confirm(`Etes-vous sûr de vouloir supprimer la culture "${culture.name}" ?`)
        if (res) {
          Api.post('/exploitation/culture/delete', {
            exploitationId: this.exploitationId,
            cultureId: culture.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.cultures = this.cultures.filter((item, index, array) => item.id !== culture.id)
                Toast.success('Culture supprimée avec succès !')
                this.allData = this.allData.filter(item => item.id != culture.id)
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              //this.fetchingNewCulture = false
            })
        }
      }
      ,
      onExport() {
        IO.exportDataTable(this.cultures, 'cultures', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des cultures',
          headers: [
            {title: 'Aperçu', key: 'picture', width: 'auto', asset: this.asset},
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Commentaire', key: 'comment', width: '*'},
          ]
        })
      }
      ,
      
      validStep() {
        return this.stepName.trim().length >= 3
      }
      ,
      onStepModalClose() {
        this.submitted = false
        this.error = null
        this.fetchingNewStep = false
        this.stepName = ''
        this.stepComment = ''
        this.selectedStep = null
        this.activitiesList = []
      }
      ,
      newStep() {
        this.submitted = true
        if (!this.validStep()) return
        
        this.fetchingNewStep = true
        Api.post('/exploitation/culture/step/create', {
          exploitationId: this.exploitationId,
          name: this.stepName,
          comment: this.stepComment,
          activities: this.activitiesList.map(item => item.id)
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newStepModal = false
              this.steps.push(res.data.data)
              this.stepCultureAutocompleteData.push({
                ...res.data.data,
                activities: this.activitiesList
              })
              this.stepCulturesList.push({
                ...res.data.data,
                activities: this.activitiesList
              })
              Toast.success('Etape de culture créée avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewStep = false
          })
      }
      ,
      updateStep() {
        this.submitted = true
        if (!this.validStep()) return
        
        this.fetchingNewStep = true
        Api.post('/exploitation/culture/step/update', {
          exploitationId: this.exploitationId,
          cultureStepId: this.selectedStep.id,
          name: this.stepName,
          comment: this.stepComment,
          activities: this.activitiesList.map(item => item.id)
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newStepModal = false
              this.steps = this.steps.filter((item, index, array) => item.id !== this.selectedStep.id)
              this.steps.push(res.data.data)
              this.stepCultureAutocompleteData = this.stepCultureAutocompleteData.map(item => item.id == res.data.data.id ? {
                ...res.data.data,
                activities: this.activitiesList
              } : item)
              let newIndex = -1
              this.allData.map(item => {
                if (item.cultureSteps.find(el => el.id == this.selectedStep.id))
                  newIndex = 1
              })
              console.log(newIndex)
              if (newIndex == 1)
                this.callResume()
              
              Toast.success('Etape de culture mise à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewStep = false
          })
      }
      ,
      onSubmitStep() {
        this.selectedStep ? this.updateStep() : this.newStep()
      }
      ,
      editStep(step) {
        this.selectedStep = step
        this.stepName = step.name
        this.stepComment = step.comment
        this.newStepModal = true
        this.activitiesList = this.stepCultureAutocompleteData.find(item => item.id == step.id).activities
      }
      ,
      deleteStep(step) {
        const res = confirm(`Etes-vous sûr de vouloir supprimer l'étape de culture "${step.name}" ?`)
        if (res) {
          Api.post('/exploitation/culture/step/delete', {
            exploitationId: this.exploitationId,
            cultureStepId: step.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.steps = this.steps.filter((item, index, array) => item.id !== step.id)
                Toast.success('Etape de culture supprimée avec succès !')
                this.allData = this.allData.map(item => ({
                  ...item,
                  cultureSteps: item.cultureSteps.filter(el => el.id != step.id)
                }))
                
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              // this.fetchingNewCulture = false
            })
        }
      }
      ,
      onExportSteps() {
        IO.exportDataTable(this.steps, 'culture-steps', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des étapes de culture',
          headers: [
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Commentaire', key: 'comment', width: '*'},
          ]
        })
      }
      ,
      
      validActivity() {
        return this.activityName.trim().length >= 3
      }
      ,
      onActivityModalClose() {
        this.submitted = false
        this.error = null
        this.fetchingNewActivity = false
        this.activityName = ''
        this.activityComment = ''
        this.selectedActivity = null
        this.equipments = []
        this.clearEquipmentAutocompleteInput()
        this.inputs = []
        this.clearInputAutocompleteInput()
      }
      ,
      newActivity() {
        this.submitted = true
        if (!this.validActivity()) return
        
        this.fetchingNewActivity = true
        Api.post('/exploitation/activity/create', {
          exploitationId: this.exploitationId,
          name: this.activityName,
          comment: this.activityComment,
          duration: this.activityDuration,
          cost: this.activityCost,
          personNumber: this.activityPersonNumber,
          equipments: this.equipments.map(item => ({
            id: item.id,
            quantity: item.quantity
          })),
          inputs: this.inputs.map(item => ({
            id: item.id,
            quantity: item.quantity
          })),
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newActivityModal = false
              this.activities.push(res.data.data)
              this.activityAutocompleteData.push(res.data.data)
              
              Toast.success('Activité créée avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewActivity = false
          })
      }
      ,
      updateActivity() {
        this.submitted = true
        if (!this.validActivity()) return
        
        this.fetchingNewActivity = true
        Api.post('/exploitation/activity/update', {
          exploitationId: this.exploitationId,
          activityId: this.selectedNodeData.id,
          name: this.activityName,
          comment: this.activityComment,
          duration: this.activityDuration,
          cost: this.activityCost,
          personNumber: this.activityPersonNumber,
          equipments: this.equipments.map(item => ({
            id: item.id,
            quantity: item.quantity
          })),
          inputs: this.inputs.map(item => ({
            id: item.id,
            quantity: item.quantity
          })),
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newActivityModal = false
              this.activities = this.activities.filter((item, index, array) => item.id !== this.selectedNodeData.id)
              this.activities.push(res.data.data)
              Toast.success('Activité mise à jour avec succès !')
              this.allData = this.allData.map(item => ({
                ...item,
                cultureSteps: item.cultureSteps.map(el => ({
                  ...el,
                  activities: el.activities.map(ac => ac.id == res.data.data.id ? res.data.data : ac)
                }))
              }))
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewActivity = false
          })
      }
      ,
      onSubmitActivity() {
        this.selectedNodeData ? this.updateActivity() : this.newActivity()
      }
      ,
      editActivity(activity) {
        this.selectedNodeData = activity
        this.activityName = activity.name
        this.activityComment = activity.comment
        this.activityDuration = activity.duration
        this.activityCost = activity.cost
        this.activityPersonNumber = activity.personNumber
        this.inputs = activity.inputs
        this.equipments = activity.equipments
        this.newActivityModal = true
      }
      ,
      deleteActivity(activity) {
        const res = confirm(`Etes-vous sûr de vouloir supprimer l'activité "${activity.name}" ?`)
        if (res) {
          Api.post('/exploitation/activity/delete', {
            exploitationId: this.exploitationId,
            activityId: activity.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.activities = this.activities.filter((item, index, array) => item.id !== activity.id)
                Toast.success('Activité supprimée avec succès !')
                console.log(this.allData)
                this.allData = this.allData.map(item => ({
                  ...item,
                  cultureSteps: item.cultureSteps.map(el => ({
                    ...el,
                    activities: el.activities.filter(ac => ac.id != activity.id)
                  }))
                }))
                console.log(this.allData)
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              // this.fetchingNewCulture = false
            })
        }
      }
      ,
      onExportActivities() {
        IO.exportDataTable(this.steps, 'activities', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des activités',
          headers: [
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Commentaire', key: 'comment', width: '*'},
          ]
        })
      }
      ,
      clearEquipmentAutocompleteInput() {
        this.$refs.equipmentAutocompleteInput.inputValue = ''
        this.equipment = ''
      }
      ,
      clearStepCultureAutocompleteInput() {
        this.$refs.stepCultureAutocompleteInput.inputValue = ''
        this.step_culture = ''
      }
      ,
      clearActivityAutocompleteInput() {
        this.$refs.activityAutocompleteInput.inputValue = ''
        this.activity = ''
      }
      ,
      removeEquipment(equipment) {
        this.equipments = this.equipments.filter(e => e.id !== equipment.id)
      }
      ,
      removeStepCulture(step_culture) {
        this.stepCulturesList = this.stepCulturesList.filter(e => e.id !== step_culture.id)
      }
      ,
      removeActivity(activity) {
        this.activitiesList = this.activitiesList.filter(e => e.id !== activity.id)
      }
      ,
      addEquipment(equipment) {
        this.submitAddEquipement = true
        if (this.validAddEquipment(this.quantityEquipment)) {
          return
        }
        if (!equipment && this.equipment.trim() !== '')
          equipment = this.equipmentAutocompleteData.find(
            e => e.name.toLowerCase().includes(this.equipment.toLowerCase())
          )
        
        if (equipment) {
          if (!this.equipments.includes(equipment))
            this.equipments.push({
              ...equipment,
              quantity: this.quantityEquipment
            })
          this.quantityEquipment = ''
        }
        else
          alert('Aucun matériel trouvé !')
        
        this.submitAddEquipement = false
        this.clearEquipmentAutocompleteInput()
      }
      ,
      validAddEquipment(val) {
        return typeof val == 'number' && val >= 0
      },

      selectCulture(item){
        
        
          if (!this.stepCulturesList.includes(item))
            this.stepCulturesList.push(item)
          else alert('Etape de culture déja ajoutée!')
                

      },

      selectActivity(item){
        
        
        if (!this.activitiesList.includes(item))
          this.activitiesList.push(item)
        else alert('Activité déja ajoutée!')
              

      },

      addStepCulture(step_culture) {
        if (!step_culture && this.step_culture.trim() !== '')
          step_culture = this.stepCultureAutocompleteData.find(
            e => e.name.toLowerCase().includes(this.step_culture.toLowerCase())
          )
        
        if (step_culture) {
          if (!this.stepCulturesList.includes(step_culture))
            this.stepCulturesList.push(step_culture)
        }
        else
          alert('Aucune étape trouvée !')
        
        this.clearStepCultureAutocompleteInput()
      }
      ,
      addActivity(actitivy) {
        if (!actitivy && this.actitivy.trim() !== '')
          actitivy = this.activityAutocompleteData.find(
            e => e.name.toLowerCase().includes(this.actitivy.toLowerCase())
          )
        
        if (actitivy) {
          if (!this.activitiesList.includes(actitivy))
            this.activitiesList.push(actitivy)
        }
        else
          alert('Aucune actvité trouvée !')
        
        this.clearActivityAutocompleteInput()
      }
      ,
      clearInputAutocompleteInput() {
        this.$refs.inputAutocompleteInput.inputValue = ''
        this.input = ''
      }
      ,
      removeInput(input) {
        this.inputs = this.inputs.filter(e => e.id !== input.id)
      }
      ,
      addInput(input) {
        if (!input && this.input.trim() !== '')
          input = this.inputAutocompleteData.find(
            i => i.name.toLowerCase().includes(this.input.toLowerCase())
          )
        
        if (input) {
          if (!this.inputs.includes(input))
            this.inputs.push({
              ...input,
              quantity: this.quantityInput
            })
        }
        else
          alert('Aucun intrant trouvé !')
        
        this.clearInputAutocompleteInput()
      }
      ,
      onSelectedNode(node) {
        const code = node.data.code
        console.log(code)
        if (code == 'CULTURE') {
          this.activatedCulture= 'de '+node.text
          if (node.children.length == 0) {
            
            this.columnActiviyModeDisabled = true
            this.columnActiviyMessage = "La culture \"" + node.text + "\" ne possède pas d'étapes de cultures"
            this.fetchingActivityInformations = false
          }else{
              this.currentCulture = node.data
              const stepscultures = this.allData.find(item => item.id == this.currentCulture.id).cultureSteps
              console.log(stepscultures)
              //this.stepscult = stepscultures
              this.treeDataStepsCultures = stepscultures.map(item => {
              return {
                text: item.name,
                data: {
                  isOwn: item.isOwn,
                  id: item.id,
                  code: 'STEP_CULTURE'
                }
              }
            })
            this.$refs.stepsCultureTree.setModel(this.treeDataStepsCultures)
            this.cultureIndex = 1;
            
          }
        }
        else if (code == 'STEP_CULTURE') {
          //this.activatedCulture= ''
          this.currentStep = node.data
          this.currentCulture = node.parent.data
          console.log(node)
          if (node.data.childrenLength == 0) {
            this.columnActiviyModeDisabled = true
            this.columnActiviyMessage = "L'étape de culture " + node.text + " ne possède pas d'activités"
            this.cultureIndex = 2; 
          } else {
            this.columnActiviyModeDisabled = false
            const activities = this.allData.find(item => item.id == this.currentCulture.id).cultureSteps
              .find(item => item.id == this.currentStep.id).activities
            console.log(activities)            
            this.treeDataActivities = activities.map(item => {
              return {
                text: item.name,
                data: {
                  isOwn: item.isOwn,
                  id: item.id,
                  code: 'ACTIVITY'
                }
              }
            })
            this.$refs.tree.setModel(this.treeDataActivities)
            this.cultureIndex = 2;
            this.fetchingActivityInformations = false
          }
        } else {
          this.currentActivity = node.data
          const otherInformations = this.allData.find(item => item.id == this.currentCulture.id).cultureSteps
            .find(item => item.id == this.currentStep.id).activities
            .find(item => item.id == this.currentActivity.id)
          this.fetchingActivityInformations = true
          this.activityDataDuration = otherInformations.duration
          this.activityDataCost = otherInformations.cost
          this.activityDataPersonNumber = otherInformations.personNumber
          this.activityDataInputs = otherInformations.inputs
          this.activityDataEquipments = otherInformations.equipments
        }
      },
      callResume() {
        this.fetchingAllCultures = true
        Api.get('/exploitation/culture/resume', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.allData = res.data.data.sort((a,b) => {
                if(a.isOWn) return -1
                else if (b.isOwn) return 1
                else return 0
              })
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingAllCultures = false
          })
        
      },
      nextComposantToVisit(){
        this.$router.push('/exploitation/' + this.exploitationId + '/production/create')
      }
    }
  }
</script>

<style scoped>
  .form-control.is-invalid{
    background-image: none !important;
  }
  
  #culture-img{
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  .autocomplete{
    width: 100%;
    border-radius: 0;
  }
  
  .num-badge{
    width: 15px;
    height: 15px;
    margin-left: -3px;
    text-align: center;
    padding: auto 0;
  }
  
  .node-number{
    margin-right: 0;
  }
  
  .tree-card{
    height: 350px !important;
  }
  
  .tree-card > div{
    overflow-y: auto;
    height: 100%;
  }
  
  .tree-card > div:nth-child(2){
    padding: 5px;
    border-right: 1px solid #c8ced3;
  }
  
  .tree-card-input{
    padding: 10px 25px 10px 15px;
  }
  
  .tree-part{
    padding: 10px 25px;
    border-right: 1px solid #c8ced3;
  }
  
  .form-control:disabled{
    background-color: #888888 !important;
  }
  
  other-title{
    font-size: 15px;
    text-decoration: underline;
    font-family: "Verdana Pro Cond";
    font-weight: 500;
  }
  
  .not-selected{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 17px;
    color: grey;
    font-family: "Segoe UI Historic";
  }
  
  .default-unit{
    margin-top: -10px;
  }
  
  default-unit{
    font-size: 20px;
    font-family: Candara;
    font-weight: bold;
    text-decoration: underline;
  }
  .node-info.for-me::after{
    content: " \2022";
    color: #009000;
    font-size: 12px;
  }
  .tree-node{
    margin-left: 0 !important;
  }

  .hover-highlight:hover {
background-color: #87CEEB;
  }   

</style>
  